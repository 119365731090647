.btnContainer {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    align-items: center;
    margin-top: 15x;
}

.formContainer{
    margin-bottom: 25px;
}

.headerTag{
    display: flex;
}

.headerContent{
    display: flex;
    justify-content: space-between;
}