.iconsContainer {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.profileImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #512da8;
  font-size: 35px;
  color: #fff;
  text-align: center;
  line-height: 100px;
  margin: 10px 0;
}

.removeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.removeBtnContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.lastLogin {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 10px;
}

.lastLoginDate {
  color: dimgray;
}

.adminInformation {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* align-items: center; */
  flex-wrap: wrap;
  padding: 0;
}

@media only screen and (max-width: 600px) {
  .teamProfileContainer {
    width: 100%;
  }
  .profileCard {
    width: 100%;
    min-width: auto;
  }
  .userInfoContainer {
    justify-content: left;
  }
  .username {
    word-break: break-all;
  }
}

.actionContainer {
  display: flex;
  justify-content: flex-end;
}

.dropdownProfile {
  padding: 0;
  width: 12px;
}
