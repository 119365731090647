.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  /* border-top: 1px solid grey; */
  padding: 15px 0px;
  margin-top: 20px;
}

.labelInput {
  font-size: 1rem;
  min-width: 12rem;
}

.infoCustomLogin {
  max-width: 30rem;
  font-size: 0.8rem;
}

.infoCustomContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
  margin-top: 15x;
}
