.isAdmin,
.userEmail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}

.saveButton {
  display: flex;
  justify-content: flex-end;
}

.addUserTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 8px;
}

.textLabel {
  min-width: 100px;
}
