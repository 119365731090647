.trace {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px; 
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
}

.codeSnippet {
  color: #ee5493;
  word-break: break-all;
}

.arrowContainer {
  display: flex;
  justify-content: center;
}

.traceHeader {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap:10px;
  flex-wrap: wrap;

}

.NodeName {
  font-style: italic;
}
