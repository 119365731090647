.btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.containerAll {
    display: flex;
    align-items: center;
}

.pContainer {
    color: #ff5521;
    font-size: 12px;
    padding-right: 10px;
    cursor: pointer;
}

.pContainerAll {
    color: #0A8FDC;
    font-size: 12px;
}


.deleteContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tableContainer{
    overflow-x: auto;
    max-height: 600px;
}
