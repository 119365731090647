table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    margin:2% auto;
    text-align: center;
    
  }
  table{
    width: 50%;
  }
  th,td{
    padding: 0%;
  }
  