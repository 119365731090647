@import '../../../style/GlobalStyle.css';

.title {
  color: var(--primary-color);
  margin-bottom: 10px;
}

.titleCs {
  color: var(--primary-color);
  margin-bottom: 10px;
  margin-left: 5px;
}

.textContainer {
  margin-top: 5px;
  margin-bottom: 20px;
}

.textBrief {
  margin-left: 20px;
}

.textBrief li {
  word-break: break-word;
}

.textBriefNew {
  height: auto;
  margin-left: 20px;
  /* max-height: 100px; */
  /* overflow-y:scroll; */
}
.preformatted {
  background: #eee;
  padding: 1rem;
  overflow: auto;
  border-radius: 3px;
}

.preformatted,
.codeContainer {
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: normal;
  word-wrap: break-word;
  line-height: 1.5;
}

.toggleContainerC{
  display: flex;
  flex-direction: row;
}

.iconCode{
  color: var(--primary-color);
  margin-left: 5px;
  cursor: pointer;
}
.iconCodeS{
  color: var(--primary-color);
  margin-left: 5px;
}
.hideiconCode{
  display: none;
}
.titleContainer{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
.subTitleContainer{
  display: flex;
}