
.autoCompleteContainer {
  display: flex;
  justify-content: center;
  margin: 10px;
  margin-top: 20px;
}

.autoComplete {
  width: 320px;
}
