
.frontPage {
  background-image: url('../../../assets/images/Dashboard/splash2.svg');
  background-size: cover;
  width: 100%; 
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: flex-end;
}

.frontInfo{
  margin-top:800px;
  margin-bottom: 200px;
  margin-left:60px;
}

.titleFrontPage{
  max-width: 800px;
  font-size: 30px;
}

.frontImageContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.frontImage {
  height:90px;
}


.mainfrontImage{ 
  width: 400px;
}