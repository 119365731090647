@import 'style/GlobalStyle.css';

.singleScanRuleContainer {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.rulesetInfo {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */
  text-align: left;
}

.rulesetIcon {
  min-width: 40px;
}

.severity {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textField {
  min-width: 150px;
  margin: 0px 10px;
}

.rulesetSubheading {
  color: var(--light-text);
}
