.autoCompleteContainer {
  display: flex;
  justify-content: center;
  margin: 10px;
  margin-top: 20px;
  flex-direction: column;
}

.autoComplete {
  width: 320px;
  height: auto;
}
