.issueHeader {
  width: 100%;
  background-color: #a4cae6;
  padding: 20px 10px;
  border-radius: 15px;
}

.subHeading {
  color: white;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content:space-evenly;
  margin-top:2%;
}

.issueTitle {
  word-wrap: break-word;
  font-size: 18px;
  font-weight: 500;
  padding: 4px;
}
.titleSection{
  display:flex;
  justify-content:space-between;
  align-items:center;
}

.titleSub{
  display:flex;
  align-items:center;
}