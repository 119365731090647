.customRulesHeader{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.subHeader{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 25px;
}


.addButton {
    display: flex;
    justify-content: flex-end;
}