:root {
    --info-color: #4DC994;
    --low-color: #19ACF1;
    --medium-color: #DF57CC;
    --high-color: #FFA40F;
    --critical-color: #FF0000;
}

.main {
    background-color: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    height: max-content;
    padding: 2%;
}
.header-container{
    display: flex;
    justify-content: space-between;
}
.modal-container{
    height: 500px;
    width: 500px;
}
.first-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.days-container{
    display: flex;
    flex-direction: column;
}
.radio-btn-container{
    display: flex;
}
.hour-sf{
    width: 40% !important;
}
.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.info-container{
    display: flex;
    justify-content: space-between;
}
.bottom-btn{
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 30px;
}
.sf-container {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
}

.fields-container {
    width: 100%;
}

.input-search {
    width: 100% !important;
}

.btn-container {
    float: right;
}

.filters-input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

.item {
    text-align: left;
    width: 100% !important;
}

.level-containers-info,
.level-containers-low,
.level-containers-m,
.level-containers-h,
.level-containers-c {
    display: flex !important;
    align-items: center !important;
}

.MuiSelect-select{
    display: flex !important;
    align-items: center !important;
}

.level-containers-info p {
    color: var(--info-color);
}

.level-containers-low p {
    color: var(--low-color);
}

.level-containers-m p {
    color: var(--medium-color);
}

.level-containers-h p {
    color: var(--high-color);
}

.level-containers-c p {
    color: var(--critical-color);
}

.filters-input {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.search-btn {
    width: 15%;
}

.css-1occpl9-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    display: flex !important;
    align-items: center !important;
}
.day-week{
    border: 1px solid #eee8e8;
    border-radius: 50px;
    color: black;
    display: inline-block;
    font-size: 15px;
    height: 35px;
    line-height: 35px;
    margin-right: 10px;
    text-align: center;
    width: 35px;
    cursor: pointer;
}
.selected {
    background-color: #3498db;
    color: white;
}
/*Tags*/
.removeTag {
    max-height: 50px;
}
/*Scan rules*/
.scanRulesHeader{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
}
.singleScanRuleContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.rulesetInfo {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  width: 550px;
  text-align: left;
}

.rulesetIcon {
  min-width: 40px;
}

.rulesetIcon2 {
  min-width: 40px;
  display: flex;
}

.rulesetIcon2div{
  margin-left: 15px;
}

.severity {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textField {
  min-width: 150px;
  margin: 0px 10px;
}

.rulesetSubheading {
  color: var(--light-text);
}
/*Jira*/
.inputContainerJira {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 15px 0px;
}

.labelInputJira {
  font-size: 1rem;
  min-width: 9rem;
}

.inputFieldJira {
  width: 400px;
}

.mainContainer {
  margin: 10px;
  overflow-x: auto;
  max-height: 85%;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.helpManual {
  max-width: 450px;
}

.helpLinks {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.singleHelpLink {
  text-decoration: none;
  color: var(--primary-color);
}

.dd{
  overflow-x: auto;
    max-height: 85%;
}
/*Edit Scan Name*/
.editModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.textField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.scanName {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}
/*Tags*/
.addBtnContainer {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0;
}
.closeBtn {
  display: flex;
  justify-content: flex-end;
  margin-left: 5px;
}
.submitBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px auto;
  align-items: center;
  display: flex;
  gap: 20px;
}
.headerTag {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
/*Custom rules*/
.customRulesHeader{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.subHeader{
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 25px;
}


.addButton {
  display: flex;
  justify-content: flex-end;
}
/*Ignore list custom rules*/
.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10;
  margin: 10px;
}
.subHeading {
  margin: 15px;
}
.formContainer {
  display: flex;
  flex-direction: column;
}
.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  /* border-top: 1px solid grey; */
  padding: 15px 0px;
  justify-content: center;
}
.labelInput {
  font-size: 1rem;
  min-width: 5rem;
}
.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 10px;
}
/*Scan Settings*/
.headerSettings{
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.settingIcon {
  min-width: 40px;
}
.scanTypeContainer {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: baseline;
  padding-top: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--border-color);
}
.scanTypeName {
  /* min-width: 300px; */
  text-align: left;
}
.scanIconName {
  display: flex;
  align-items: center;
}
/*Advanced*/
.advanceHeader{
  display: flex;
  justify-content: center;
  align-items:center;
}
.singleScanRuleContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}
.singleScanRuleContainer2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}
.rulesetIcon {
  min-width: 40px;
}
.rulesetSubheading {
  color: var(--light-text);
}
/*Delete modal*/
.deleteModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* Cell styles */
/* .css-1oph4ye-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center!important;
  -webkit-justify-content: center!important;
  justify-content: center!important;
  cursor: pointer;
  text-align: center;
} */

.MuiDataGrid-columnHeaderTitleContainer{
  -webkit-box-pack: center !important;
  -ms-flex-pack: center!important;
  -webkit-justify-content: center!important;
  justify-content: center!important;
}
/* Id's modal */
.custom-modal {
  & .MuiBackdrop-root {
    background-color: transparent;
  }
  
  
  & .Paper-root {
    background-color: transparent;
  }
}
.header-modal {
  display: flex;
  align-items: center;
}
.content-modal-id {
  display: flex;
  flex-direction: column;
  color: white;
}
table, th, td {
    border: none !important;
}