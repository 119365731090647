:root {
    --info-color: #4DC994;
    --low-color: #19ACF1;
    --medium-color: #DF57CC;
    --high-color: #FFA40F;
    --critical-color: #FF0000;
}

.main {
    background-color: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    height: max-content;
    padding: 2%;
}

.dt-container {
    height: auto;
}

.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.sf-container {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
}

.fields-container {
    width: 100%;
}

.input-search {
    width: 100% !important;
}

.btn-container {
    float: right;
}

.filters-input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

.item {
    text-align: left;
    width: 100% !important;
}

.dt-container {
    padding-top: 3%;
}

.dt-container .MuiTablePagination-displayedRows {
    display: none !important;
}

.level-containers-info,
.level-containers-low,
.level-containers-m,
.level-containers-h,
.level-containers-c {
    display: flex !important;
    align-items: center !important;
}

.MuiSelect-select{
    display: flex !important;
    align-items: center !important;
}

.level-containers-info p {
    color: var(--info-color);
}

.level-containers-low p {
    color: var(--low-color);
}

.level-containers-m p {
    color: var(--medium-color);
}

.level-containers-h p {
    color: var(--high-color);
}

.level-containers-c p {
    color: var(--critical-color);
}

.filters-input {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.search-btn {
    width: 15%;
}

.css-1occpl9-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    display: flex !important;
    align-items: center !important;
}
.modal-content{
    word-wrap: break-word;
    padding: 10px 14px 10px 10px;
    display: flex;
    flex-direction: column; 
}
.buttons-wrapper{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.btn-sr{
    min-width: 25px;
}