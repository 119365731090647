@import '/src/style/GlobalStyle.css';

.singleUserSetup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 25px 0px;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
  gap: 20px;
}

.singleUserInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  width:400px;
}

.lockPasswordReset,
.deleteUser {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nameEmail {
  display: flex;
  flex-direction: column;
  margin: 1px 5px;

}

.nameEmail > .email {
  word-break: break-word;
  width: 250px;
}

.roleDropdown {
  width: 180px;
}


.lastLoggedIn {
  font-size: smaller;
  color: dimgrey;
}


.rolesAssigned {
  width: 180px;
}

.selectRole{
  word-wrap: break-word;
}

@media only screen and (max-width: 950px) {
  .singleUserSetup {
    flex-direction: inherit;
  }
  .lockPasswordReset,
  .deleteUser {
    padding: 10px 0px;
  }
  .roleDropdown {
    width: inherit;
  }
}

.assignOrgButton {
  border-radius: 20px;
}

.actionContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}


.activeUser {
  opacity: 0.5;
}

.hideDiv {
  display: none !important;
}

.showDiv{
  display: block;
}

.deleteUserContainer {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-direction: column;
  align-items: center;
}

.deleteBtnContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
}

.chipRole{
  font-size: 12px;
  height: 25px;
  margin: 2px;
  cursor: pointer;
  background-color: #0c8fdc;
  color: #ffff;
}
