@import url('../../../style/GlobalStyle.css');
.heading {
  text-transform: uppercase;
  margin: 5px auto;
}

.text {
  font-size: 13px;
  color: var(--medium-text);
}
