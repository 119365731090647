.titleContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  height:30rem;
  overflow-y: scroll;
  width: 100%;
  padding-bottom: 10px;
  
}

.formActionContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.addOrgRoleHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:6px
}

/* .tableContainer {
  max-height: 400px;
  overflow-y: auto;
} */

table, th, td {
  border: none !important;
}