.commentNotAvailable {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.commentTagContainerBtn {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
