
.frontPage {

  width: 100%; 
  display: flex;

  flex-direction: column;
  justify-content: flex-end;
}

.frontInfo{
  margin-top:10%;
  margin-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
}

.titleFrontPage{
  max-width: 800px;
  font-size: 60px;

}

.frontImageContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.frontImage {
  height:90px;
}


.mainfrontImage{ 
  width: 400px;
}