.companyHeader {
  display: flex;
  flex: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.companyHeaderTitle {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
}

.manageCompanyButton {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: flex-end;
  gap: 15px;
}

.companyForm {
  margin: 20px 0;
}

.twoFContainer {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
