.main {
    background-color: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    height: max-content;
    padding: 2%;
}
.headerContainer {
    display: flex;
    justify-content: space-between;
}

.btnContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 10px;
    align-items: center;
    cursor: pointer;
    color: #0A8FDC;
}

.pContainer {
    color: #0A8FDC;
    font-size: 12px;
}

.pContainer.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

.deleteContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}