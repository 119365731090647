@import url(../../../style/GlobalStyle.css);

.scanTypeInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:20px
}

.singleScanType{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
    padding: 15px 0;
}

.settingIcon{
    display: flex;
    justify-content: center;
    align-items: center;
}