.formContainer {
  margin: 10px;
  display: flex;
  justify-content: row;
  gap: 20px;
  /* flex-wrap: wrap; */
}

.removeTag {
  max-height: 50px;
}

.removeBtnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.headerTag {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 5px;
}

.issuesContainer {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}

.submitBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px auto;
  align-items: center;
  display: flex;
  gap: 20px;
}

.labelIssue {
  font-size: 14px;
  font-weight: 400;
}
