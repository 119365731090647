@import '../../../../style/GlobalStyle.css';

.scanTypeContainer {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;

  padding-top: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--border-color);
}

.scanTypeName {
  /* min-width: 300px; */
  text-align: left;
}

.scanIconName {
  display: flex;
  align-items: center;
}

.settingIcon {
  min-width: 50px;
}
