@import 'style/GlobalStyle.css';

.textLabel {
  min-width: 13rem;
}

.ManageProfileForm {
  width: 100%;
}

.groupLabelInput {
  display: flex;
  flex-wrap: wrap;
  gap: 10;
  margin: 20px 0;
  border-bottom: 1px solid var(--border-color);
  justify-content: space-between;
  align-items: center;
}


.groupPhoneNumber {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.internationalCode {
  width: 50%;
}

.phone_num {
  width: 48%;
}

.alertSub {
  color: var(--light-text);
  font-size: 13px;
}

.btnContainer{
  margin-left: 5px;
}