.historyItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.historyItemTitle {
  /* display: flex;
    flex-direction: row;
    align-items: center; */
}


.tagChip{
  color:white;
  margin:2px;
  padding:3px 6px;
  background-color: var(--button-primary);
  border-radius: 3px;
}


.tagsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:4px;
  margin:5px auto;
  min-height: fit-content;
}