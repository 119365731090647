.modalChildrenMessage { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 10px
}

.message {
    text-align: center;
}