.primaryText{
    color: rgb(7, 100, 154);
    font-weight: 700;
    margin-top: 4%;
}
.firstHeading{
    color: rgb(7, 100, 154);
    font-weight: 700;
}
.textLabel {
    min-width: 13rem;
  }
  

.groupPhoneNumber {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .internationalCode {
    width: 50%;
  }
  
  .phone_num {
    width: 48%;
  }
.modal{
    background-color: white;
    border-radius: 0.4rem;
    width: 40%;
    height: fit-content;
    margin: 2% auto;
    padding: 2%;
    display: flex;
    flex-direction: column;
}
.modalCard{
    overflow-y: hidden;
}
.buttons{
    margin: 2% 0;
}