.autoCompleteSelection{
    margin-top:5px;
    margin-bottom: 10px;
}

.formSubBlock{
    margin-top:10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.btnSubmit {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.tagsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:4px;
    margin:5px auto;
    overflow-y: auto;
    max-height: 235px;
}
/* .tagChip{
    color:white;
    margin:2px;
    padding:3px 6px;
    background-color: var(--button-primary);
    border-radius: 3px;
} */

.badgeTag {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: row;
    color: #0c8fdc;
    background-color: transparent;
    border-radius: 16px;
    text-decoration: none;
    right:20px;
    padding-left:5px;
    font-size: 10px;
    height: auto;
    border: 1px solid #0c8fdc;
}

.inactiveTag {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    color: red;
    background-color: transparent;
    border-radius: 16px;
    text-decoration: none;
    right:20px;
    padding-left:5px;
    font-size: 10px;
    height: 20px;
    border: 1px solid red;
}

.tagChipAction {
    margin-top: 4px;
    text-decoration: none;
    color: #0c8fdc;
}

.inactiveChipAction {
    margin-top: 4px;
    text-decoration: none;
    color: red;
}

/* .tagChipDelete {
    text-decoration: none;
    color:white;
    padding-left:5px;
    padding-right:5px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: small;
    color: #feca37;
    border-radius:50%;border: 1px solid #feca37;
    margin-top:5px;
    margin-bottom:5px;
} */

.aTagInactive{
    text-decoration: none;
      color:red;
      padding-left: 4px;
      padding-right: 3px;
      margin-left: 4px;
      margin-right: 4px;
      font-size: 12px;
      color:red;
      margin-top: 1px;
      margin-bottom: 3px;
  }

.aTag2{
    text-decoration: none;
      color: #0c8fdc;
      padding-left: 4px;
      padding-right: 3px;
      margin-left: 4px;
      margin-right: 4px;
      font-size: 12px;
      color: #0c8fdc;
      margin-top: 1px;
      margin-bottom: 3px;
  }

.commentArea{
    border: 1px solid silver;
    padding: 9px;
    outline: 0;
    font-size: 14px;
    border-radius: 8px;
    height: 150px;
    width: 100%;
    resize: none;
}

.headerContainerComment{
    display: flex;
    margin-bottom: 10px;
}

.commentIconHeader{
    margin-right: 5px;
}

.pLabels{
    color: rgb(107, 114, 128);
}

.btnContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
}
.autocomplete-disabled {
    color: grey;
    background-color: #f0f0f0;
    pointer-events: none;
}
.disabledDetails{
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
}
  
