@import '/src/style/GlobalStyle.css';
.singleReport {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border-color);
  padding: 10px 0px;
  margin-top: 20px;
  width: 100%;
}

.securityGateInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 450px;
  cursor: pointer;
  margin-top: 15px;
  
}


/* .name {
  margin-top: 0px
} */


.securityGateIcon {
  min-width: 40px;
}

.criticalText {
  color: var(--critical);
  font-weight: bolder;
}

.highText {
  color: var(--high);
  font-weight: bolder;
}

.mediumText {
  color: var(--medium);
  font-weight: bolder;
}

.lowText {
  color: var(--low);
  font-weight: bolder;
}

.configureTextSection {
  position: relative;
  flex-direction: row;
    display: flex;
    font-size: 12px;
}
.configureText {
  color: #209ef7;
  /* position: absolute; */
  top: 10%;
  left: 7%;
  margin-left: 5px;
}
.configureTextNoPosition {
  position: initial;
  color: #209ef7;
}
.singleIcon {
  height: 20px;
  width: 20px;
  cursor: pointer;

}

/* .digitSecIcon {
  height: 17px;
  width: 25px;
} */

.linkText {
  text-decoration: none;
  color: #209ef7;
}

.numberOfErrors {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
}

.errorStatus {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.textField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.scanName {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}
.repContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.customTags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  margin-left: 40px;
  grid-gap: 2px;
  width: 40%;
  /* gap: 20px; */
}

.scanType{
  font-size: 10px;
  /* color: #fff; */
}

.sastColor {
  background-color: #ff0000;
  color: #ffffff;
}

.iastColor {
  background-color: #92e092e1;
  color: #000000;
}

.scaColor {
  background-color: #0c8fdc;
  color: #ffffff;
}

.configColor {
  background-color: #fece0e;
  color: #000000;
}

.thirdPartyColor {
  background-color: #ef81e3;
  color: #ffffff;
}

.qualityColor {
  background-color: #8993f6;
  color: #ffffff;
}

.defaultColor{
  background-color: #ff0000;
  color: #ffffff;
}

.showNewFindings{
  background-color: chocolate;
  color:#ffffff
}

.typesMainContainer{
  display: flex;
  flex-direction: row;
}

.typesContainer{
  width: -webkit-fill-available;
  margin-left: 15px;
}

.schedulerIcon{
  height: 20px;
  width: 20px;
  color: #0A8FDC;
  cursor: pointer;
}



