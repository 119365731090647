@media all {
  .pagebreak {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@media print {
  .pagebreak {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 15mm;
}

.pdfStyle {
  width: 1010px;
  max-width: 1010px;
  margin: 10px;
  background-color: rgb(255, 255, 255);
  /* border: 1px solid green; */
  margin: 0 auto;
  padding: 10px 25px;
}
