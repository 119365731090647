.modalTitle {
  text-align: center;
  margin:5px;
}

.btnGroup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
