@import '/src/style/GlobalStyle.css';
.routeLink {
  text-decoration: none;
  color: inherit;
}
.routeLink:hover {
  color: var(--primary-color);
}
.orgInfoContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.iconContainer {
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  justify-content:flex-start;
  margin-top: 10px;
}

.singleIcon {
  height: 20px;
  width: 20px;
}

.adminInformation {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
}

.orgBottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.lastScanDate {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
}


.lastScanType {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
}

.dateStyle {
  /* font-style: normal;
  font-size: normal; */
  color: dimgray;
}

.headingCard {
  cursor: pointer;
}

.textCard{
  font-size: 10px;
  /*font-style: italic;*/
}

.typeTags {

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  color:white;
  background-color:#0A8FDC;
  border-radius: 5px;
  text-decoration: none;
  right:20px;
  padding-left:5px;
  padding-right:5px;

}

.customTags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  grid-gap: 2px;
  width: 100%;
  height: auto;
  /* gap: 20px; */
}

@media only screen and (max-width: 600px) {
  .boxSalesforceDetail{
    justify-content: flex-start !important;
  }
  .boxSalesforceImage{
    justify-content: space-between;
  }
}

/* @media screen and (min-width: 890px) and (max-width: 1537px) {
  .lastScanType {
     font-size: 10px;
  }
  .dateStyle2{
    font-size: 10px;
  }
} */