@import '/src/style/GlobalStyle.css';
.badgeTag {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  color: #0c8fdc;
  background-color: transparent;
  border-radius: 16px;
  text-decoration: none;
  right:20px;
  padding-left:5px;
  font-size: 10px;
  height: auto;
  border: 1px solid #0c8fdc;
}

.badgeTagInactive {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  color: red;
  background-color: transparent;
  border-radius: 16px;
  text-decoration: none;
  right:20px;
  padding-left:5px;
  font-size: 10px;
  height: auto;
  border: 1px solid red;
}
/* .icon{
  transform: scale(0.8);
  height:18px;
  background-color:#0A8FDC;
  margin-top:2px;
} */
.aTag{
  text-decoration: none;
  color: #0c8fdc;
  margin-top:3px;
}

.aTagInactive{
  text-decoration: none;
  color: red;
  margin-top:3px;
}

.aTag2{
  text-decoration: none;
    color: #0c8fdc;
    padding-left: 4px;
    padding-right: 3px;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 12px;
    color: #0c8fdc;
    margin-top: 1px;
    margin-bottom: 3px;
}

.aTag2Inactive{
  text-decoration: none;
    color: red;
    padding-left: 4px;
    padding-right: 3px;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 12px;
    color: red;
    margin-top: 1px;
    margin-bottom: 3px;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
