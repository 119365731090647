@import url('style/GlobalStyle.css');

.mainContainer {
  margin: 10px 20px;
}

.installCopado {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  margin-top: 20px;
  justify-content: flex-start;
}

.installCopadoTitle {
  width: 160px;
  text-align: left;
}

.copadoLink {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: bold;
  padding: 15px;
  text-decoration: none;
  border-radius: 10px;
  text-align:center;
  min-width: 170px;
}
