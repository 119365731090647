@import url('style/GlobalStyle.css');
.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 15px 0px;
}

.labelInput {
  font-size: 1rem;
  min-width: 9rem;
}

.inputField {
  width: 400px;
}

.mainContainer {
  margin: 10px;
  overflow-x: auto;
  max-height: 85%;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.helpManual {
  max-width: 450px;
}

.helpLinks {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.singleHelpLink {
  text-decoration: none;
  color: var(--primary-color);
}

.dd{
  overflow-x: auto;
    max-height: 85%;
}
