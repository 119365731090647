@import url(../../style/GlobalStyle.css);

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary-color);
  gap: 10px;
  margin: 10px 0;
}

.subHeading {
  color: var(--light-text);
}
