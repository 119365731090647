.inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 15px 0px;
  }
  
  .labelInput {
    font-size: 1rem;
    min-width: 14rem;
  }
  
  .inputField {
    width: 600px;
  }
  