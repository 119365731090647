.nameEmail {
  display: flex;
  flex-direction: column;
  margin: 1px 5px;
}

.passwordContainer {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  text-align: center;
}

.deleteBtnContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
}
