@import url(../../../style/GlobalStyle.css);

.singleIntegrationInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.SingleIntegration {
  border-bottom: 1px solid var(--border-color);
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.integrationActionContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 15px;
  flex-wrap: wrap;
}
.resetSSOIntegration {
  padding: 5px 10px;
  font-size: 13px;
}

.downloadLink {
  text-decoration: none;
}

.intLogo {
  max-width: 70px;
  max-height: 70px;
}
