.ChartContainer {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
}
.chart {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.legendStyle li::marker {
  font-size: 2em;
}
