.scanSettingsHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.scanSettingsInfo {
  display: flex;
  flex-direction: column;
}

.rulesetSub {
  color: var(--light-text);
  font-size: 12px;
}

.switchContainer {
  display: flex;
  align-items: center;
}
