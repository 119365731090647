.buttonBoxContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  padding: 1.5rem;
  border-top: 1px solid gray;
  width: 100%;
  max-width: 550px;
  justify-content: space-between;
}

.buttonBoxHeading {
  font-size: 1rem;
}

.btn {
  width: 100%;
  max-width: 180px;
}

.link {
  border-radius: 5px;
  color: rgba(0, 162, 255, 0.89);
  padding: 7px 15px;
  border: 1px solid rgba(10, 143, 220, 0.5);
  text-decoration: none;
  font-weight: 500;
  width: 100%;
  max-width: 180px;
  text-align: center;
}

.link:hover {
  color: rgba(0, 162, 255, 0.445);
  border: 1px solid rgb(10, 143, 220);
}
