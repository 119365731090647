.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 50px;
  align-items: flex-end;
  margin-right: 20px;
  margin-bottom: 20px;
}


.contactContainer{
    text-align: right;
}

.confidentialInformation {
  max-width: 300px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.listContainer {
  list-style: none;
  text-align: right;
  text-decoration: none;
}
