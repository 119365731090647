.filters-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

.filter-icon{
    font-size: 20px;
    margin-left: 5px;
    cursor: pointer;
    color: #0a8fdc;
}

.filter-icon-container{
    display: flex;
    cursor: pointer;
    align-items: center;
}

.filter-icon-container p{
    color: #0a8fdc;
    font-size: 12px;
}

.search-btn {
    width: 15%;
}

.selectedItem {
    font-weight: bold;
  }
  