.textField {
  width: 310px;
}

.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
.exportBtnDiv {
  display: flex;
  width: auto;
  justify-content: flex-end;
  width: 310px;
}

@media only screen and (max-width: 600px) {
  .textField {
    width: 231px;
    margin: auto;
  }
  .autoComplete {
    margin: auto;
  }
}

.newFindingContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.filtersContainer.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.newFindingContainer p{
  font-size: 12px;
}

.menuIcon{
  color: #19ACF1;
}

.resetBtn{
  width: 125px;
  margin-right: 5px;
}


