.primaryText{
    color: rgb(7, 100, 154);
    font-weight: 400;
    margin-top: 2%;
}
.firstHeading{
    color: rgb(7, 100, 154);
    font-weight: 400;
}
.divider{
    margin-bottom: 2%;
}
.QrImg{
    margin: auto;
    width: 250px;
    height: 250px;
}
.imgContainer{
    display: flex;
    justify-content: center;
}
.modal{
    background-color: white;
    border-radius: 0.4rem;
    width: 40%;
    height: fit-content;
    margin: 2% auto;
    padding: 2%;
    display: flex;
    flex-direction: column;
}
.modalCard{
    overflow-y: hidden;
}
.buttons{
    margin: 2% 0;
}