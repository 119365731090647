.vpContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 15px;
  max-width: 420px;
}

.btnContainer {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
}

.MuiBox-root .css-4cdj6i{
  display: flex !important;
  justify-content: center !important;
}
