@import 'style/GlobalStyle.css';

.trendingScans {
  max-height: 500px;
  background-color: red;
}

.scanStatsList {
  list-style-position: inside;
  margin-left: 10px;
}

.accordionContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.accordion {
  box-shadow: none;
}

.componentScanned {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.activeScanRule {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  /* max-width: 300px; */
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 4px;
}

.scanRuleInfoContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.customTooltip {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 5px;
  border-radius: 4px;
  color: white;
}
