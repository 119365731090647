:root {
  --border-color: rgba(0, 0, 0, 0.12);
  --button-primary: #0a8fdc;

  --critical: rgb(255, 0, 0);
  --high: rgb(171, 9, 6);
  --medium: rgb(245, 162, 0);
  --low: rgb(15, 154, 238);

  --light-text: #a5a6aa;
  --medium-text: #454545;
  --primary-color: #0c8fdc;
}