@import '../../../style/GlobalStyle.css';

.title {
  color: var(--primary-color);
}

.title h3{
  word-wrap: break-word;
}

.subTitle {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 5px;
}

.subTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn {
  margin-left: 10px;
}

.anchorStyle{
  display: flex;
  text-decoration: none;
  color: var(--primary-color);
  align-items: center;
}

.backDashboardBtn {
  margin-bottom: 20px;
}

.complianceImgContainer {
  display: flex;
  gap: 10px;
}

.complianceImg {
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.btnActionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.complianceModal {
  display: flex;
  flex-direction: column;
  gap:20px;
  margin: 30px;
}

.orderlist{
  margin-left: 20px;
}

.cpModalHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
}