.advanceHeader{
    display: flex;
    justify-content: center;
    align-items:center;
    margin-bottom: 10px;
}

.globalAdvanceHeader {
    display: flex;
    justify-content: start;
    align-items:center;
}

.singleScanRuleContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.rulesetInfo {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  text-align: left;
}

.rulesetIcon {
  min-width: 40px;
}

.severity {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textField {
  min-width: 150px;
  margin: 0px 10px;
}

.rulesetSubheading {
  color: var(--light-text);
}

.hiddenDesc {
  display: none;
}

.rulesetSub {
  color: var(--light-text);
  font-size: 12px;
}

.scanAdvancedInfo{
  display: flex;
}

.switchContainer{
  margin-bottom: 10px;
}


