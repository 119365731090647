.inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    /* border-top: 1px solid grey; */
    padding: 15px 0px;
    margin-top: 15px;
  }
  
  .labelInput {
    font-size: 1rem;
    min-width: 15rem;
  }
  
  .autoCompleteInput {
    min-width: 480px;
  }
  
  .inputTextfield {
    min-width: 420px;
  }

  .formHeight {
  height:30rem;
  }
  