.otpVerifyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 15px;
  max-width: 450px;
}

.btnContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}
