.accordionContainer {
  margin-top: 10px;
}

.link {
  text-decoration: none;
}

.navigation {
  margin: 0px 10px;
}
