@import url('/src/style/GlobalStyle.css');
.singleReport {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;

  width: 100%;
  max-width: 500px;
  min-height: 400px;
  gap: 20px;

  border-radius: 25px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.pieChart {
  display: flex;
  align-items: center;
  flex-direction: column;
  width:200px;
  height:200px;
}

.singleReport:hover {
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
}

.singleReport:hover .icon {
  color: rgb(15, 154, 238);
}

.valueContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.securityGateInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
}

.name {
  margin-top: 5px;
}

.criticalText {
  color: var(--critical);
  font-weight: bolder;
}

.card {
  border-radius: 20px;
  padding: 9px;
}

.highText {
  color: var(--high);
  font-weight: bolder;
}

.mediumText {
  color: var(--medium);
  font-weight: bolder;
}

.lowText {
  color: var(--low);
  font-weight: bolder;
}

.configureText {
  color: #209ef7;
}

.linkText {
  text-decoration: none;
  color: #209ef7;
}

.numberOfErrors {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
}

.errorStatus {
  width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}


.showNewFindings{
  background-color: chocolate;
  color:#ffffff
}