.legendStyle {
  margin-left: 100;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
  list-style-type: square;
  background-color: rgb(238, 237, 237);
  list-style-position: inside;
  padding: 20px;
  width: fit-content;
  border-radius: 20px;
}
