@media all {
  .pagebreak {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@media print {
  .pagebreak {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 15mm;
}
.issuesContainer {
  margin: 100px 0px;
}

.detailContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 30px 0;
}
