.topSalesforceBarContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 25px 0;
  align-items: center;
  gap: 20px;
}

.searchCategoryContainer {
  display: flex;
  flex-direction: row;
  gap: 25px;
  flex-wrap: wrap;
}

.orgNotFound {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.searchField,
.dropdownContainer {
  width: 300px;
}
