.inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    /* border-top: 1px solid grey; */
    padding: 15px 60px;
    margin-top: 15px;
  }
  
  .labelInput {
    font-size: 1rem;
    min-width: 15rem;
  }
  
  .autoCompleteInput {
    min-width: 480px;
  }
  
  .inputTextfield {
    min-width: 360px;
  }
  
  /* .updateForm {
    margin:20px 30px;
    height:400px;
    overflow-y: scroll;
  } */

  .formContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 370px;
    max-height: 900px;
    background-color: var(--background-paper);
    box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.12);
    padding: 16px;
    border-radius: 10px;
  }

.btnContainer {
  display: flex;
  justify-content: flex-end;
  gap:15px;
  align-items: center;
  margin-top: 15x;
  padding-right: 40px;
}

.formHeight {
  height:30rem;
}

.mainContainer{
  display: flex;
    justify-content: center;
    height: 400px;
    overflow-y: scroll;
}