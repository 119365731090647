@import url('/src/style/GlobalStyle.css');

.reportPending {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.errorMessage {
    color: var(--medium-text);
}