.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10;
  margin: 10px;
}

.subHeading {
  margin: 15px;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  /* border-top: 1px solid grey; */
  padding: 15px 0px;
  justify-content: center;
}

.labelInput {
  font-size: 1rem;
  min-width: 5rem;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 10px;
}
