.headerTag {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.addBtnContainer {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0;
}

.closeBtn {
  display: flex;
  justify-content: flex-end;
  margin-left: 5px;
}

.submitBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px auto;
  align-items: center;
  display: flex;
  gap: 20px;
}

.rolefieldsContainer {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.fieldContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
  margin-top: 20px;
}
.fieldLabel {
  min-width: 95px;
}
