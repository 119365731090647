.heading{
    margin: 3% auto;
    color: rgb(7, 100, 154);
}
.para{
    margin: 3% 0;
}
.loginLink{
    text-align: center;
    margin: 2% 0;
    cursor: pointer;
}
.authBtn{
    display: block;
    padding: 3%;
    margin: 3% auto;
    width: 50%;
    margin-bottom: 16%;
    
}