.securityGateFrom {
  /* max-width: 400px; */
  width: 100%;
}

label {
  display: block;
  padding: 10px 0;
}
.labelCheckbox {
  display: block;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
}

.btn {
  padding: 7px;
  margin: 10px;
}

.required:after {
  content: ' *';
  color: red;
}

.textFieldInput {
  min-width: 100%;
}

.switchContainerSg {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.rulesetSub {
  color: var(--light-text);
  font-size: 12px;
}

.selectContainer{
  width: 370px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.subContainer{
  margin-top: 10px;
}

.hideContainer{
  display: none;
}

.showContainer{
  display: block;
}

.chkContainer{
  display: none;
}
