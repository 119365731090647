@import url('/src/style/GlobalStyle.css');
@media all {
  .pagebreak {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@media print {
  .pagebreak {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 15mm;
}

.issuesContainer {
  margin: 50px 0px;
}

.headerIssues {
  margin: 50px 0;
  border: 1px solid var(--border-color);
  border-top: 2px solid var(--border-color);
  padding: 20px;
  border-radius: 5px;
}
.complianceViolation {
  margin: 20px 0;
}

.violationLists {
  list-style-position: inside;
}
