.dashboardContainer {
  display: flex;
  flex-direction: column;
}

.switchOrgContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
