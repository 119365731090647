.placeHolderContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 200px;
    max-width: 600px;
    margin: 0 auto;
    gap: 20px;
}